:root {
  --red: #eb0d31;
  --toolbar-height: 5.6rem;
  --message-offset: 0px;
}

@font-face {
  font-family: BatonTurboWeb;
  src: url(../fonts/BatonTurboWeb-Regular.eot);
  src: url(../fonts/BatonTurboWeb-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BatonTurboWeb;
  src: url(../fonts/BatonTurboWeb-Medium.eot);
  src: url(../fonts/BatonTurboWeb-Medium.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 12px;
}

html,
body,
button {
  font-family: BatonTurboWeb, Arial, "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: calc(var(--toolbar-height) + var(--message-offset));
}

.Polaris-TextField__Input {
  height: 2.4rem;
}

.client-name {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.unwrapped > * {
  flex-wrap: unset;
  max-width: 200px;
  overflow-wrap: anywhere;
}

.Polaris-Frame__TopBar {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.multiline-text {
  white-space: normal;
  max-width: 300px;
}

.inline {
  display: inline;
}

.clickable {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .not-on-mobile {
    display: none;
  }
  .reg-new-client-link > .Polaris-Link,
  .reg-new-client-link > div > .Polaris-Link {
    width: 100%;
  }
}

/* Set max-width of first column for Polaris DataTable */
.Polaris-DataTable__Cell--firstColumn {
  max-width: 300px;
}

.Polaris-DataTable__Cell--header {
  font-size: 1rem;
  color: #676767;
  font-weight: 600;
}

.new-info {
  background-color: #effdff;
  font-weight: 600;
}

.Polaris-DataTable__Cell--header,
.Polaris-DataTable__Heading {
  color: #676767;
  font-size: 1rem;
  font-weight: 600;
}

/* Style for DataTable cells with subdued TextStyle */
.Polaris-DataTable__Cell > .Polaris-TextStyle--variationSubdued {
  font-size: 1rem;
  font-weight: 600;
}

.Polaris-DataTable__Cell > div > .Polaris-TextStyle--variationSubdued {
  font-size: 1rem;
  font-weight: 600;
}

/* bump the z-index for modal on top of another modal to extends the backdrop to cover the lower modal */
#PolarisPortalsContainer > [data-portal-id|="modal"]:last-of-type .Polaris-Modal-Dialog__Container {
  z-index: 520;
}

#PolarisPortalsContainer > [data-portal-id|="modal"]:last-of-type .Polaris-Backdrop {
  z-index: 519;
}
